import React from 'react';
import ReactPlayer from 'react-player/youtube';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import firebase from 'gatsby-plugin-firebase';
import { FaGoogle, FaFacebook, FaYelp } from 'react-icons/fa';

import { Button, Slider, Slide } from '../components';

import IconPlay from '../../static/icons/play.png';
import Thumbnail from '../../static/backgrounds/video-player.png';
import ServicesCarpet from '../../static/images/services/carpet.png';
import ServicesJanitorial from '../../static/images/services/janitorial.png';
import ServicesBlinds from '../../static/images/services/blinds.png';
import ServicesFloor from '../../static/images/services/floor.png';

import menu from '../data/menu';

import styles from './index.module.scss';

const ServicesItem = ({
  title, image, description, path,
}) => (
  <li className={styles.servicesItemWrapper}>
    <img className={styles.servicesItemImage} src={image} alt="" />
    <p className={styles.servicesItemTitle}>{title}</p>
    <p className={styles.servicesItemDescription}>{description}</p>
    <Button
      buttonClassName={styles.servicesItemButton}
      onClick={() => navigate(path)}
      label="Reed more"
      theme="secondary"
      isShowArrow
    />
  </li>
);

const socialIcons = {
  google: <FaGoogle size={16} color="d04e37" />,
  yelp: <FaYelp size={16} color="d1262b" />,
  facebook: <FaFacebook size={16} color="227bef" />,
};

export default function Home() {
  const { contentfulHomepage: contentfulHome, allContentfulTestimonial, allContentfulOffer } = useStaticQuery(graphql`
    {
      contentfulHomepage {
        heroTitle
        heroSubtitle
        reviewVideo
        enableSpecialOffers
        offersSectionTitle
        poweredTitle
        poweredSubtitle
        aboutTitle
        aboutDescription {
          aboutDescription
        }
        aboutSectionImage {
          resize (width: 1000) {
            src
          }
        }
        servicesTitle
        servicesDescriptionCarpet
        servicesDescriptionJanitorial
        servicesDescriptionBlinds
        servicesDescriptionFloor
        workTitle
        workSectionSteps {
          title
          data
        }
        processTitle
        processSteps {
          description
          title
        }
        showTestimonials
        testimonialsSectionTitle
      }
      allContentfulTestimonial {
        nodes {
          avatar {
            file {
              url
            }
          }
          name
          description {
            description
          }
          social
          link
        }
      }
      allContentfulOffer {
        nodes {
          title
          description {
            description
          }
          image {
            file {
              url
            }
          }
        }
      }
    }
  `);

  const services = [
    {
      title: 'Carpet',
      image: ServicesCarpet,
      description: contentfulHome.servicesDescriptionCarpet,
      path: menu.CARPET.path,
    },
    {
      title: 'Janitorial',
      image: ServicesJanitorial,
      description: contentfulHome.servicesDescriptionJanitorial,
      path: menu.JANITORIAL.path,
    },
    {
      title: 'Blinds',
      image: ServicesBlinds,
      description: contentfulHome.servicesDescriptionBlinds,
      path: menu.BLINDS.path,
    },
    {
      title: 'Floor',
      image: ServicesFloor,
      description: contentfulHome.servicesDescriptionFloor,
      path: menu.FLOOR.path,
    },
  ];

  return (
    <main>
      {/* HERO */}
      <div className={styles.hero}>
        <div className="container">
          <h1 className="heading">{contentfulHome.heroTitle}</h1>
          <h2 className="subheading">{contentfulHome.heroSubtitle}</h2>
          {/* <Button label="Calculate Price" /> */}
        </div>
      </div>

      {/* VIDEO PLAYER */}
      <div className={styles.player}>
        <div className="container">
          <div className={styles.playerWrapper}>
            <ReactPlayer
              url={contentfulHome.reviewVideo}
              width="100%"
              height="100%"
              light={Thumbnail}
              playIcon={<img src={IconPlay} alt="Play Video" />}
              onStart={() => {
                firebase.analytics().logEvent('video_start');
              }}
            />
          </div>
        </div>
      </div>

      {/* Offers */}
      {contentfulHome.enableSpecialOffers && !!allContentfulOffer.nodes.length && (
        <div className={styles.offers}>
          <div className="container">
            <h4 className={styles.sectionTitle}>
              {contentfulHome.offersSectionTitle.split(' ').slice(0, 1).join(' ')}
              <span className={styles.sectionTitleAccent}>
                {' '}
                {contentfulHome.offersSectionTitle.split(' ').slice(1).join(' ')}
              </span>
            </h4>

            <Slider>
              {allContentfulOffer.nodes.map((item, key) => (
              // eslint-disable-next-line react/no-array-index-key
                <Slide key={`offer-${key}`}>
                  <div className={styles.offerWrapper}>
                    <img
                      src={item.image.file.url}
                      alt={`offer-${key}`}
                      className={styles.offerImage}
                    />
                    <div className={styles.offerContent}>
                      <p className={styles.offerTitle}>{item.title}</p>
                      <p className={styles.offerDescription}>{item.description.description}</p>
                    </div>
                  </div>
                </Slide>
              ))}
            </Slider>
          </div>
        </div>
      )}

      {/* Powered */}
      <div className={styles.powered}>
        <div className="container">
          <h3 className={styles.poweredTitle}>{contentfulHome.poweredTitle}</h3>
          <p className={styles.poweredSubtitle}>{contentfulHome.poweredSubtitle}</p>
          {/* <Button label="Calculate Price" /> */}
        </div>
      </div>

      {/* About */}
      <div className={styles.about}>
        <div className="container">
          <div className={styles.aboutWrapper}>
            <div className={styles.aboutContent}>
              <h3 className={styles.sectionTitle}>
                {contentfulHome.aboutTitle.split(' ').slice(0, 2).join(' ')}
                {' '}
                <span className={styles.sectionTitleAccent}>
                  {contentfulHome.aboutTitle.split(' ').slice(2).join(' ')}
                </span>
              </h3>
              <p className={styles.aboutDescription}>
                {contentfulHome.aboutDescription.aboutDescription}
              </p>
            </div>

            <div className={styles.aboutImageWrapper}>
              <img
                className={styles.aboutImage}
                src={contentfulHome.aboutSectionImage.resize.src}
                alt="About Us"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Services */}
      <div className={styles.services}>
        <div className="container">
          <h3 className={styles.servicesTitle}>
            {contentfulHome.servicesTitle}
          </h3>
          <ul className={styles.servicesList}>
            {services.map((item) => <ServicesItem key={item.title} {...item} />)}
          </ul>
        </div>
      </div>

      {/* Work */}
      <div className={styles.work}>
        <div className="container">
          <h3 className={styles.sectionTitle}>
            <span className={styles.sectionTitleAccent}>{contentfulHome.workTitle.split(' ').slice(0, 1).join(' ')}</span>
            {' '}
            {contentfulHome.workTitle.split(' ').slice(1).join(' ')}
          </h3>
          <div className={styles.workLists}>
            {contentfulHome.workSectionSteps.map((item, index) => (
              <div key={item.title} className={styles.workList}>
                <p className={styles.workListTitle}>
                  <span className={styles.sectionTitleAccent}>{`0${index + 1} `}</span>
                  {item.title}
                </p>
                {item.data.map((dataItem) => (
                  <div
                    key={dataItem}
                    className={styles.workListItem}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: dataItem }}
                  />
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Process */}
      <div className={styles.process}>
        <div className="container">
          <h3 className={styles.servicesTitle}>{contentfulHome.processTitle}</h3>
          <ul className={styles.processList}>
            {contentfulHome.processSteps.map((item, index, array) => (
              <li key={item.title} className={styles.processListItem}>
                <ul className={styles.circleList}>
                  {Array(array.length).fill().map(
                    (_, circleIndex) => (
                      <li
                        // eslint-disable-next-line react/no-array-index-key
                        key={`circle-${circleIndex}}`}
                        className={`${styles.processCircle} ${circleIndex > index ? '' : styles.processCircleFilled}`}
                      />
                    ),
                  )}
                </ul>
                <p className={styles.processListTitle}>{item.title}</p>
                <p
                  className={styles.processListDescription}
                  dangerouslySetInnerHTML={{ __html: item.description }}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Testimonials */}
      {contentfulHome.showTestimonials && (
        <div className={styles.testimonials}>
          <div className="container">
            <h3 className={styles.sectionTitle}>
              <span className={styles.sectionTitleAccent}>
                {contentfulHome.testimonialsSectionTitle.split(' ').slice(0, 1).join(' ')}
              </span>
              <br />
              {contentfulHome.testimonialsSectionTitle.split(' ').slice(1).join(' ')}
            </h3>
            <Slider>
              {allContentfulTestimonial.nodes.map((item) => (
                <Slide key={item.name}>
                  <div className={styles.testimonialItem}>
                    <img
                      className={styles.testimonialAvatar}
                      src={item.avatar.file.url}
                      alt={item.name}
                    />
                    <div className={styles.testimonialContent}>
                      <p className={styles.testimonialAuthor}>
                        {item.name}
                        {' '}
                        <a
                          href={item.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles.testimonialLink}
                        >
                          {socialIcons[item.social]}
                        </a>

                      </p>

                      <p
                        className={styles.testimonialReview}
                      >
                        {item.description.description}
                      </p>
                    </div>
                  </div>
                </Slide>
              ))}
            </Slider>
          </div>
        </div>
      )}
    </main>
  );
}
